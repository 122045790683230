import React from 'react';

interface FeaturesData {
  imgSrc: string;
  heading: string;
  subheading: string;
}

const featuresData: FeaturesData[] = [
  {
    imgSrc: "/images/relaibility.png",
    heading: "Unmatched Reliability",
    subheading:
      "Our reports are built on verified data sources, ensuring accuracy, completeness, and reliability, covering all critical information.",
  },
  {
    imgSrc: "/images/traceability.png",
    heading: "Complete Traceability",
    subheading:
      "We provide clear data source details for transparency, allowing you to verify and trust the insights we deliver.",
  },
  {
    imgSrc: "/images/turnaround.png",
    heading: "Quick Turnaround Time",
    subheading:
      "Using advanced AI and NLP, we deliver fast, detailed reports, ensuring quick access to the insights you need without delay.",
  },
  {
    imgSrc: "/images/nodb.png",
    heading: "No Data Gaps",
    subheading:
      "Our coverage blends quantitative and qualitative data for a full market view, ensuring no key details are missed.",
  },
];

const Features = () => {
  return (
    <div
      className="mx-auto max-w-7xl my-0 md:my-40 pt-36 px-6 relative"
      id="features-section"
    >
      <div className="radial-bg hidden lg:block"></div>
      <div className="grid lg:grid-cols-2 gap-x-4 gap-y-4 justify-center items-center flex">
        {/* Column-1 */}
        <div>
          <h3 className="feature-font text-lg font-semibold mb-4 text-center md:text-start">
            FEATURES
          </h3>
          <h2 className="text-offwhite text-2xl md:text-4xl font-semibold leading-snug mb-6 text-center md:text-start">
            Assured Industry Intelligence
          </h2>
          <p className="lg:text-lg font-normal text-bluish text-center md:text-start">
            Assured Industry Intelligence provides accurate, expert-driven
            solutions to help businesses stay ahead of trends and optimize
            performance.
          </p>
        </div>
        {/* Column-2 */}
        <div>
          <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-2">
            {featuresData.map((item, index) => (
              <div className="bg-blue py-5 p-5 rounded-lg" key={index}>
                <div className="rounded-full h-16 w-16 flex items-center justify-center mb-4">
                  <img
                    src={item.imgSrc}
                    alt={item.heading}
                    className="h-full w-full object-contain"
                  />
                </div>
                <h5 className="text-offwhite text-xl font-medium mb-2">
                  {item.heading}
                </h5>
                <p className="text-lightblue text-base font-normal">
                  {item.subheading}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
