import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import axios from "axios";
import RootLayout from "../layout";
import API_BASE_URL from "../utils";
import { useNavigate } from 'react-router-dom';

const Categories: React.FC = () => {
  const { sub_cat_id } = useParams<{ sub_cat_id: string }>();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (sub_cat_id) {
      axios
        .get(`${API_BASE_URL}getDocumentByCategory?sub_cat_id=${sub_cat_id}`)
        .then((response) => {
          setData(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
          setError("Failed to fetch data.");
          setLoading(false);
        });
    }
  }, [sub_cat_id]);

  const navigate = useNavigate();

  const handleRedirect = (queryName: string, documentId: number) => {
    navigate(`/article/${queryName}/${documentId}`);
  }
  return (
    <>
      <div className="relative flex justify-center items-center mt-8 bg-offwhite">
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center opacity-70"
          style={{ backgroundImage: "url('https://webbackend.andtelligence.com/file-12974864163257720.jpeg')" }}
        ></div>
        <div
          className="w-full max-w-7xl mx-auto flex justify-center items-center h-60 bg-cover bg-center relative"
          style={{ backgroundImage: "url('https://webbackend.andtelligence.com/file-12974864163257720.jpeg')" }}
        >
          <p className="text-black font-medium text-2xl bg-white bg-opacity-80 px-4 py-2 rounded-full z-10">
            {data?.sub_category}
          </p>
        </div>
      </div>

      <div className="relative w-full max-w-7xl mx-auto mt-20 px-6 min-h-[70vh]">
        <Grid container spacing={4} justifyContent="center">
          {data?.document?.map((value: any) => (
            <Grid item xs={12} md={6} key={value?.document_id}>
              <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 h-52">
                <img
                  className="object-cover w-full rounded-t-lg h-full md:h-full md:w-48 md:rounded-none md:rounded-s-lg"
                  src={value?.image}
                  alt="Document Image"
                />
                <div className="flex flex-col justify-between p-4 leading-normal h-full">
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">
                    {value?.heading_1}
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 dark:text-black">
                    {value?.heading_2}
                  </p>
                  <div className="flex cursor-pointer items-center" onClick={() => handleRedirect(value?.query_name, value?.document_id)}>
                    <p className="text-base font-medium text-[#CF2318] text-opacity-75">
                      {"Read More >>"}
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default Categories;
