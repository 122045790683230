import { useState, useEffect } from "react";
import axios from "axios";
import { IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import API_BASE_URL from "../utils";

const Navbar = () => {
  const [isArticleDropdownOpen, setIsArticleDropdownOpen] = useState(false);
  const [subDropdown, setSubDropdown] = useState<number | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // State for mobile drawer
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}getCategories`);

        if (response.status === 200) {
          setCategories(response.data.data);
        } else {
          setError("Error fetching categories");
        }
      } catch (err) {
        setError("An error occurred while fetching categories");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <nav className="bg-gradient-to-r from-[#0C0B27] via-[#1B164F] to-[#020A1E] sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Left side: Logo */}
          <div className="flex-shrink-0">
            <a href="/" className="cursor-pointer">
              <img
                src="/images/Logo/&telligence_dark.png"
                alt="Logo"
                className="h-12"
              />
            </a>
          </div>

          {/* Menu button for mobile */}
          <div className="block md:hidden">
            <IconButton onClick={toggleDrawer} color="inherit">
              <MenuIcon style={{ color: "white" }} />
            </IconButton>
          </div>

          {/* Right side: Navigation links */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4 relative">
              {/* Articles Dropdown */}
              <div
                onMouseEnter={() => setIsArticleDropdownOpen(true)}
                onMouseLeave={() => setIsArticleDropdownOpen(false)}
                className="relative"
              >
                <a
                  href="/articles"
                  className="text-white hover:bg-[#4B5563] px-3 py-2 rounded-md text-lg font-medium"
                >
                  Articles
                </a>

                {/* Dropdown */}
                {isArticleDropdownOpen && (
                  <div className="absolute right-0 top-full mt-1 w-56 bg-white shadow-lg rounded-md z-50">
                    {categories.map((article: any, index) => (
                      <div
                        key={index}
                        onMouseEnter={() => setSubDropdown(index)}
                        onMouseLeave={() => setSubDropdown(null)}
                        className="relative group"
                      >
                        <span className="block px-4 py-1 text-gray-800 hover:bg-[#E5E7EB] cursor-pointer">
                          {article.title}
                        </span>

                        {/* Sub-dropdown */}
                        {subDropdown === index && (
                          <div className="absolute left-full top-4 mt-0 w-56 bg-white shadow-lg rounded-md z-50">
                            {article.subTopics.map(
                              (subTopic: any, subIndex: number) => (
                                <Link
                                  key={subIndex}
                                  to={`/category/${subTopic.sub_cat_id}`} // Use Link for navigation
                                  className="block px-4 py-1 text-gray-800 hover:bg-[#E5E7EB] cursor-pointer"
                                >
                                  {subTopic.name}
                                </Link>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <a
                href="/about-us"
                className="text-white hover:bg-[#4B5563] px-3 py-2 rounded-md text-lg font-medium"
              >
                About Us
              </a>
              <a
                href="/contact-us"
                className="text-white hover:bg-[#4B5563] px-3 py-2 rounded-md text-lg font-medium"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            height: "100%",
            backgroundColor: "rgba(12, 11, 39, 0.9)", // Optional: semi-transparent background
            color: "white", // Optional: text color
            overflow: "hidden", // Prevent scrolling in the drawer
          },
        }}
      >
        <div className="flex justify-between items-center p-4 bg-gradient-to-r from-[#0C0B27] via-[#1B164F] to-[#020A1E] text-white">
          <h2 className="text-lg font-bold">Menu</h2>
          <IconButton onClick={toggleDrawer} color="inherit">
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </div>
        <div className="flex flex-col p-4">
          <a href="/" className="text-black hover:bg-[#D1D5DB] p-4 rounded-md">
            Home
          </a>
          <div>
            <div
              onMouseEnter={() => setIsArticleDropdownOpen(true)}
              onMouseLeave={() => setIsArticleDropdownOpen(false)}
              className="relative"
            >
              <span className="text-black hover:bg-[#D1D5DB] p-4 rounded-md cursor-pointer">
                Articles
              </span>

              {/* Mobile Dropdown */}
              {isArticleDropdownOpen && (
                <div className="absolute left-0 top-full mt-1 w-48 bg-white shadow-lg rounded-md z-50">
                  {categories.map((article: any, index) => (
                    <div
                      key={index}
                      onMouseEnter={() => setSubDropdown(index)}
                      onMouseLeave={() => setSubDropdown(null)}
                      className="relative group"
                    >
                      <span className="block px-4 py-2 text-gray-800 hover:bg-[#E5E7EB] cursor-pointer">
                        {article.title}
                      </span>

                      {/* Sub-dropdown */}
                      {subDropdown === index && (
                        <div className="absolute left-full top-0 mt-0 w-48 bg-white shadow-lg rounded-md z-50">
                          {article.subTopics.map(
                            (subTopic: any, subIndex: number) => (
                              <a
                                key={subIndex}
                                href={`/category/${subTopic.sub_cat_id}`} // Use a tag for navigation
                                className="block px-4 py-1 text-gray-800 hover:bg-[#E5E7EB] cursor-pointer"
                              >
                                {subTopic.name}
                              </a>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <a
            href="/about-us"
            className="text-black hover:bg-[#D1D5DB] p-4 rounded-md"
          >
            About Us
          </a>
          <a
            href="/contact-us"
            className="text-black hover:bg-[#D1D5DB] p-4 rounded-md"
          >
            Contact Us
          </a>
        </div>
      </Drawer>
    </nav>
  );
};

export default Navbar;
