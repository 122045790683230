import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// MIDDLE LINKS DATA
interface ProductType {
  id: number;
  section: string;
  link: string[];
}

interface Social {
  imgsrc: string;
  href: string;
}

const products: ProductType[] = [
  {
    id: 1,
    section: "Useful Links",
    link: ["Home", "Exchange", "Features", "FAQ"],
  },
];

const socialLinks: Social[] = [
  { imgsrc: "/images/Footer/insta.svg", href: "https://instagram.com/" },
  { imgsrc: "/images/Footer/dribble.svg", href: "https://dribble.com/" },
  { imgsrc: "/images/Footer/twitter.svg", href: "https://twitter.com/" },
  { imgsrc: "/images/Footer/youtube.svg", href: "https://youtube.com/" },
];

const Footer: React.FC = () => {
  return (
    <div
      className="relative"
      style={{
        background:
          "linear-gradient(45deg, #0C0B27, #1B164F, #1C1A58, #10184B, #091239, #020A1E)",
      }}
    >
      <div className="radial-bg hidden lg:block"></div>
      <div className="mx-auto max-w-2xl mt-2 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid-cols-1 gap-y-10 gap-x-16 sm:grid-cols-2 lg:grid-cols-12 xl:gap-x-8 flex">
          {/* COLUMN-1 */}
          <div className="col-span-6 mt-6">
            <img
              className="block h-12 w-20px mb-4"
              src="/images/Logo/&telligence_dark.png"
              alt="Crypto-Logo"
            />
            <h3 className="text-lightblue text-sm font-normal leading-9 mb-4">
              Cryptocurrency is a type of virtual currency that uses
              cryptography to secure transactions that are digitally recorded on
              a distributed ledger, such as a blockchain.
            </h3>
            <div className="flex justify-between items-center w-full">
              <div className="mb-4 text-lightblue font-medium text-lg w-2/4">
                <p>Subscribe to our newsletter</p>
                <div className="flex">
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter your email"
                    variant="outlined"
                    fullWidth
                    sx={{
                      background: "white",
                      borderRadius: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Button variant="outlined" color="warning">
                    Subscribe
                  </Button>
                </div>
              </div>
              <div className="w-2/5 flex flex-col justify-center items-center">
                <img
                  src="/images/startupindia.png"
                  alt="number-icon"
                  width={200}
                  height={200}
                />
                <p className="text-offwhite">Reg No: 6756GGTA442</p>
              </div>
            </div>

            <div className="flex gap-4 items-center">
              {socialLinks.map((items, i) => (
                <a href={items.href} key={i} target="_blank" rel="noopener noreferrer">
                  <img
                    src={items.imgsrc}
                    alt={items.imgsrc}
                    className="footer-icons"
                  />
                </a>
              ))}
            </div>
          </div>

          {/* COLUMN-2 */}
          <div className="col-span-4 mt-6">
            <h3 className="text-white text-xl font-medium mb-9">Contact Us</h3>
            <h4 className="text-offwhite text-sm font-normal mb-6 flex gap-2">
              <img
                src="/images/Footer/number.svg"
                alt="number-icon"
                width={20}
                height={20}
              />
              (406) 555-012
            </h4>
            <h4 className="text-offwhite text-sm font-normal mb-6 flex gap-2">
              <img
                src="/images/Footer/email.svg"
                alt="email-icon"
                width={20}
                height={20}
              />
              tim.jennings@example.com
            </h4>
            <h4 className="text-offwhite text-sm font-normal mb-6 flex gap-2">
              <img
                src="/images/Footer/address.svg"
                alt="address-icon"
                width={20}
                height={20}
              />
              Elgin St. Celina, Delaware 10299
            </h4>
          </div>
        </div>
      </div>

      {/* All Rights Reserved */}
      <div className="py-8 px-4 border-t border-t-lightblue">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 flex justify-between items-center">
          <h3 className="text-center text-offwhite opacity-75 mx-auto">
            @2024 - All Rights Reserved by &telligence.com
          </h3>
          <a
            href="https://madewithloveinindia.org"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white opacity-75"
          >
            Made with{" "}
            <span aria-label="Love" style={{ color: "#f43f5e" }}>
              &hearts;
            </span>{" "}
            in India
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
