import React, { useState, useEffect } from "react";

interface Testimonial {
  id: number;
  text: string;
  position: string;
  company: string;
  image: string;
}

const TestimonialSlider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const items: Testimonial[] = [
    {
      id: 1,
      text: "I am quite buoyant about “&telligence” becoming the standard bearer for Aerospace & Defence Sector.",
      position: "Associate Director",
      company: "Boston Consulting Group",
      image: "/images/bcg.png",
    },
    {
      id: 2,
      text: "&telligence has provided consulting and custom research services to our firm. Can vouch for the quality of his work.",
      position: "Associate Director - Industrials and Defence Sector",
      company: "Equirus Capital",
      image: "/images/equaris.png",
    },
    {
      id: 3,
      text: "&telligence Technologies has been a valuable partner for MGF - Kavachh, providing market research and advisory services that have helped refine our investment strategy. Their insights, especially in complex, data-scarce environments, have been invaluable in our due diligence efforts.",
      position: "Managing Partner",
      company: "Mounttech Growth Fund-Kavachh",
      image: "/images/kavachh.png",
    },
  ];

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!isHovered) {
      timer = setInterval(() => {
        goToNext();
      }, 3000); // Change slide every 3 seconds
    }

    return () => {
      if (timer) clearInterval(timer); // Cleanup timer
    };
  }, [isHovered, currentIndex]);

  return (
    <div className="relative w-full max-w-7xl mx-auto my-20 px-6 flex-col flex justify-center items-center">
      <div className="text-center mb-10" id="2">
        <h3 className="text-offwhite text-2xl md:text-4xl font-bold mb-3">
          Hear From Our Clients
        </h3>
      </div>
      <div
        className="flex justify-center items-center"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="overflow-hidden relative w-4/5 flex rounded-xl">
          <div
            className="flex transition-transform duration-300"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              width: `${items.length * 100}%`,
            }}
          >
            {items.map((testimonial) => (
              <div
                key={testimonial.id}
                className="flex-shrink-0 w-full p-4 flex justify-center items-center"
                style={{ background: "linear-gradient(#1a2c66, #131c44)" }}
              >
                <div className="flex justify-center items-center">
                  <img
                    src={testimonial.image}
                    alt="Client logo"
                    width={100}
                    height={100}
                    className="divide-x-2e"
                  />
                  <div className="w-px h-24 bg-white justify-center items-center ml-3"></div>

                  <div className="max-w-screen-md text-center ml-2 mr-4">
                    <img
                      src="/images/arrows.png"
                      alt="Arrow graphic"
                      width={20}
                      height={20}
                      className="mb-4 ml-4"
                    />
                    <div className="flex justify-center items-center">
                      <p className="text-xl italic font-medium text-white ml-4 w-[90%]">
                        {testimonial.text}
                      </p>
                    </div>

                    <div className="flex justify-end items-end">
                      <img
                        src="/images/arrowsBottom.png"
                        alt="Arrow graphic"
                        width={20}
                        height={20}
                        className="mt-4"
                      />
                    </div>
                    <figcaption className="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
                      <div className="flex items-center justify-center divide-x-2 rtl:divide-x-reverse divide-gray-500">
                        <div className="flex justify-center items-center">
                          <cite className="ps-3 text-sm text-white">
                            {testimonial.position}
                          </cite>
                          <p className="ml-2 mr-2 text-white">|</p>
                          <cite className="text-sm text-white">
                            {testimonial.company}
                          </cite>
                        </div>
                      </div>
                    </figcaption>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center">
            <button
              onClick={goToPrevious}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white p-3"
            >
              &lt;
            </button>
            <button
              onClick={goToNext}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white p-3"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
