import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import { Grid, Box } from "@mui/material";
import ShadowRoot from "react-shadow-root";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import API_BASE_URL from "../utils";

const DetailedArticle: React.FC = () => {
  const { filename, document_id } = useParams<{
    filename: string;
    document_id: string;
  }>();

  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [documentData, setDocumentData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSummary, setSummary] = useState(true);

  const fetchDocumentData = async (filename: any, document_id: any | any[]) => {
    try {
      const response = await axios.get(`${API_BASE_URL}getDocumentById`, {
        params: {
          query_name: filename,
          document_id: document_id,
        },
      });
      return response?.data?.data;
    } catch (error) {
      console.error("Error fetching document data:", error);
      throw new Error("Failed to fetch document data");
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (filename && document_id) {
        try {
          const data = await fetchDocumentData(filename, document_id);
          setDocumentData(data);

          if (data?.document_link) {
            const response = await axios.get(data.document_link, {
              responseType: "text",
            });
            console.log(response, "response");

            setHtmlContent(response.data);
          } else {
            throw new Error("Document link not found");
          }
        } catch (error) {
          setError("Something went wrong");
        } finally {
          setLoading(false);
        }
      }
    };

    getData();
  }, [filename, document_id]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  console.log(documentData, "documentData");

  return (
    <>
      <div className="bg-white">
        <div className="">
          <div className="mx-auto max-w-7xl px-6">
            <div className="pt-4">
              <p className="text-base pt-2">{documentData?.sub_category}</p>
              <p className="text-2xl">{documentData?.heading_1}</p>
              <p className="font-medium text-3xl pt-5">
                {documentData?.heading_2}
              </p>
              <p className="text-lg pt-1">{documentData?.heading_3}</p>
            </div>
            <div className="flex justify-between my-4 md:flex-row flex-col w-1/2">
              <div className="flex flex-col">
                <p className="font-semibold text-base text-navyblue">
                  Release Date
                </p>
                <p className="font-normal">{documentData?.release_date}</p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-base text-navyblue">
                  Base Year
                </p>
                <p className="font-normal">{documentData?.base_year}</p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-base text-navyblue">Region</p>
                <p className="font-normal">{documentData?.region}</p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-base text-navyblue">
                  Research Code
                </p>
                <p className="font-normal">DOC-00{documentData?.document_id}</p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-base text-navyblue">
                  Report Format
                </p>
                <p className="font-normal">{documentData?.format}</p>
              </div>
            </div>
          </div>

          <div
            className="flex justify-center"
            style={{
              backgroundImage: `url('/images/abg.jpg')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              // height: "100vh",
            }}
          >
            <div className="max-w-7xl px-6 mt-4 w-full">
              <div className="border-b-[3px] border-black flex justify-between w-[75%] mb-4">
                <div className="mb-4 gap-4">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: isSummary ? "#1A2B6D" : "#ccc",
                      marginRight: "50px",
                    }}
                    size="medium"
                    onClick={() => setSummary(true)}
                  >
                    Summary
                  </Button>
                  {/* <Button
                    variant="contained"
                    style={{
                      backgroundColor: !isSummary ? "#1A2B6D" : "#ccc",
                    }}
                    size="medium"
                    onClick={() => setSummary(false)}
                  >
                    TOC
                  </Button> */}
                </div>
                <div>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#1A2B6D" }}
                    size="medium"
                  >
                    Request Sample
                  </Button>
                </div>
              </div>

              <Grid container>
                <Grid item xs={12} md={9}>
                  <Box sx={{ width: "100%", overflow: "auto" }}>
                    {isSummary && (
                      <div className="h-[180vh] overflow-auto rounded-md border border-x-blue mb-4 p-4 bg-white">
                        {loading && <p>Loading...</p>}
                        {error && <p>{error}</p>}
                        {htmlContent && (
                          <ShadowRoot>
                            <div
                              className="htmlContent"
                              dangerouslySetInnerHTML={{ __html: htmlContent }}
                            />
                            <style>
                              {`
                          .htmlContent {
                            font-family: Arial, sans-serif;
                          }

                          .htmlContent h1 {
                            font-size: 2em; /* Ensure this is the size you want */
                            font-weight: bold;
                            text-decoration: underline;
                          }

                          .htmlContent h2,
                          .htmlContent h3,
                          .htmlContent p {
                            margin: 0;
                            padding: 0;
                          }

                          .htmlContent p {
                            margin-bottom: 1em;
                          }

                          .htmlContent ul,
                          .htmlContent ol {
                            margin: 1em 0;
                            padding-left: 1.5em;
                          }

                          .htmlContent ul {
                            list-style-type: disc;
                          }

                          .htmlContent ol {
                            list-style-type: decimal;
                          }

                          .htmlContent table {
                            border-collapse: collapse;
                            width: 100%;
                          }

                          .htmlContent th,
                          .htmlContent td {
                            border: 1px solid #000;
                            padding: 8px;
                          }
                        `}
                            </style>
                          </ShadowRoot>
                        )}
                      </div>
                    )}
                    {!isSummary && (
                      <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-white mt-4">
                        <div className="p-3">
                          <p className="font-semibold text-2xl text-[#1C77C3] mb-4">
                            TABLE OF CONTENT
                          </p>

                          <div
                            className="mb-2 font-medium border-b cursor-pointer flex items-center"
                            onClick={toggleOpen}
                          >
                            {/* Icon at the beginning */}
                            <div className="mr-2">
                              {isOpen ? (
                                <ExpandMore style={{ fontSize: 40 }} />
                              ) : (
                                <ChevronRightIcon style={{ fontSize: 40 }} />
                              )}
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <p className="p-4 text-xl">
                                1. Benefits of Blogging
                              </p>
                              <p className="p-4 text-xl font-bold">3</p>
                            </div>
                          </div>
                          {isOpen && (
                            <ul className="pl-20 list-disc text-lg">
                              <li className="mb-2">100% accurate reports</li>
                              <li className="mb-2">Best in industry</li>
                              <li className="mb-2">Experts on call</li>
                            </ul>
                          )}

                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            2. Rank on search engines
                          </p>
                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            3. Educate your leads and customers
                          </p>
                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            4. Defense Research
                          </p>
                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            5. Convert leads and prospects
                          </p>
                          <p className="mb-2 border-b cursor-pointer p-4 text-xl">
                            6. Become known as an expert
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={{ width: "100%", height: "100%", marginLeft: "10px" }}
                  >
                    <div className="flex flex-row justify-between items-center w-full">
                      <div className="">
                        <img
                          src="/images/notepad.png"
                          alt="notpad graphic"
                          width={90}
                          height={90}
                        />
                      </div>
                      <div className="justify-center items-center flex flex-col">
                        <button
                          type="button"
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                        >
                          Get a quote
                        </button>
                        <button
                          type="button"
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                        >
                          Inquire now
                        </button>
                        <button
                          type="button"
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                        >
                          Speak to an analyst
                        </button>
                      </div>
                    </div>
                    {/* disabled for articles */}
                    {/* 
                    <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-[#F5F5F5] mb-4">
                      <div className="p-5">
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="singleUser"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Single User
                            </label>
                            <p className="font-bold text-lg">$4350</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="multiUser"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Multi User
                            </label>
                            <p className="font-bold text-lg">$5500</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="enterprise"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Enterprise
                            </label>
                            <p className="font-bold text-lg">$6500</p>
                          </div>
                        </div>
                        <div className="flex justify-center items-center">
                          <button
                            type="button"
                            className="text-white bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                          >
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-[#F5F5F5]">
                      <div className="p-5">
                        <p className="font-semibold text-lg text-[#1C77C3]">
                          RELATED TOPICS
                        </p>
                        <p className="mb-2 font-medium border-b cursor-pointer">
                          Indigenous weapons/equipment
                        </p>
                        <p className="mb-2 font-medium border-b cursor-pointer">
                          Advanced Short Range
                        </p>
                        <p className="mb-2 font-medium border-b cursor-pointer">
                          F-35 jets
                        </p>
                        <p className="mb-2 font-medium border-b cursor-pointer">
                          Defense Research and Development Organisation
                        </p>
                        <p className="mb-2 font-medium border-b cursor-pointer">
                          Next-Generation Destroyer (NGD)
                        </p>
                        <p className="mb-2 font-medium border-b cursor-pointer">
                          IAF Collaborates
                        </p>
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailedArticle;
