interface CardProps {
    image: string;
    title: string;
}

const Card: React.FC<CardProps> = ({ image, title }) => (
    <div
        className="max-w-xs bg-white border border-gray-200 rounded-xl shadow h-80 overflow-hidden
        transition-transform duration-300 ease-in-out transform hover:scale-105"
    >
        <div
            className="h-full bg-cover bg-center rounded-lg"
            style={{ backgroundImage: `url(${image})` }}
        >
            <div className="p-5 h-full flex flex-col justify-end bg-gradient-to-t from-black/90 via-black/50 to-transparent">
                <h5 className="mb-2 text-xl font-bold tracking-tight text-white">
                    {title}
                </h5>
                <button className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg border w-32">
                    Read more
                    <svg
                        className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                        aria-hidden="true"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
);

const Table = () => {
    const cardsData: CardProps[] = [
        {
            image: '/article1.jpg',
            title: 'Noteworthy technology acquisitions 2021',
        },
        {
            image: '/article2.jpg',
            title: 'The future of AI in business',
        },
        {
            image: '/article3.jpg',
            title: 'Top 10 programming languages in 2021',
        },
        {
            image: '/article4.jpg',
            title: 'Blockchain beyond cryptocurrencies',
        },
        {
            image: '/article5.jpg',
            title: 'Cybersecurity trends for 2021',
        },
        {
            image: '/article6.jpg',
            title: 'Remote work: The new normal',
        },
    ];

    return (
        <>
            <div className='mx-auto max-w-7xl pt-40 px-6 flex flex-col justify-center items-center' id="exchange-section">
                <div className='text-center' id='2'>
                    <h3 className='text-offwhite text-2xl md:text-4xl font-bold mb-3'>Trending Reports</h3>
                    <p className='text-bluish md:text-base font-normal leading-8'>Stay informed with the latest insights from our team of experts.</p>
                </div>
                <div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-20 mt-24' id="3">
                    {cardsData.map((card, index) => (
                        <Card
                            key={index}
                            image={card.image}
                            title={card.title}
                        />
                    ))}
                </div>
            </div>
            <img src='/images/Table/Untitled.svg' alt="ellipse" className="md:mb-40 md:-mt-6" />
        </>
    );
};

export default Table;
