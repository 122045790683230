import React from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

const Simple = () => {
    return (
        <div className="simple-bg relative">
            <div className="simpleone"></div>
            {/* <div className="simpletwo"></div> */}
            <div className="simplethree"></div>
            <div className="simplefour"></div>
            <div className="simplefive"></div>
            <div className="mx-auto max-w-5xl py-24 px-6">
                <h3 className="text-center text-offwhite text-3xl lg:text-5xl font-semibold mb-6">
                    Join the waitlist
                </h3>
                <p className="text-center text-bluish text-xl font-normal mb-8">
                    Sign up for the latest updates & to join the waitlist for early access.
                </p>
                <p className="text-center text-[#FF0000] text-base font-normal mb-5">
                    Only company emails are allowed
                </p>
                <div className="flex justify-center">
                    <TextField id="outlined-basic"
                        placeholder='Enter your email'
                        fullWidth
                        variant="outlined"
                        sx={{ background: 'white', borderRadius: '10px', marginRight: '10px' }} />
                    <Button variant="contained" endIcon={<SendIcon />} color='success' style={{backgroundColor: "purple"}}>
                        Send
                    </Button>
                </div>
                <p className="text-center text-green text-base font-normal mt-5">
                    Your email has been added to our waiting list. You can unsubscribe it anytime
                </p>
            </div>
            <div className="simplesix"></div>
            {/* <div className="simpleseven"></div> */}
            <div className="simpleeight"></div>
            <div className="simplenine"></div>
            <div className="simpleten"></div>
        </div>
    );
};

export default Simple;
